$main-color: #ff0067;

*,
*:before,
*:after {
  box-sizing: border-box;
}

#top-nav {
  position:fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
}

#primary{
  li{
    float: right;
    margin:45px 0px 20px 0px;
    padding-right: 50px;
    position:relative;
    a{
      text-decoration:none;
      // display:flex;
      font-family:'Source Code Pro',monospace;
      font-weight:400;
      font-style:normal;
      font-size: 16pt;
      line-height:1;
      position:relative;
      // z-index:1;
      text-align:right;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(
          to right,
          $main-color,
          $main-color 50%,
          #56667f 50%);
      background-size: 200% 100%;
      background-position: -100%;
      transition: all 0.3s ease-in-out;
      &:before{
        // display:flex;
        content:'';
        width:0;
        height:2px;
        // bottom:0px;
        bottom:-5px;
        z-index:0;
        position:absolute;
        background:$main-color;
        transition: all 0.3s ease-in-out;
      }
      &:hover{
        background-position: 0%;
        &:before{
          width:100%;
        }
      }
    }
  }
}