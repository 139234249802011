$primary-color: #ff0067;
@import 'animate.css';

@import url('https://fonts.googleapis.com/css2?family=Condiment&family=Outfit&display=swap');
$outfit: Outfit, sans-serif;

@import url("https://use.typekit.net/rzn6lae.css");

body {
    font-family: 'Source Sans', sans-serif;
}
