:root {
  // --cardWidth: 80vw;
  --cardHeight: auto;
  // --cardMargin: 4vw;
}

body {
  overflow: hidden;

}

.project.flex-zone {
  // padding-top: 38px;
  // margin-top:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.project.flex-zone::-webkit-scrollbar {
  display: none;
}

.tile-wrapper {
  height: var(--cardHeight);
  position: relative;
  border-radius: 0%;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;

  &:hover {
    filter: none; 
    opacity: 1;
    transition: all 0.5s ease;
    transform: scale(1.2); 
  }
  .img-wrapper img {
    max-width: 100%;
    min-height: 60%;
    overflow: hidden;
  }

  .title {
    color: #ff0067;
    font-family: 'Source Code Pro',monospace;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    padding-left: 0;
  }

  .svg-projects {
    color: #ff0067;
    float:left;
    position: relative;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    border-radius: 100%;
    &:hover {
      color: #fff;
    }
  }

.project-text-zone {
  align-items: left;
  justify-content: left;
}

@media (max-width: 1280px) {
  section {
    column-count: 1;
  }
  .content {
    max-width: auto;
  }
  svg {
    position:absolute;
  // font-size: 15px;
  padding-left: 0;
  }

  /* Mobile Devices 280px and Under */
  @media (max-width: 360px) {
    .mobile {
      display: content;
    }
}}
}