body {
  background: #20252d;
  overflow: scroll;
  overflow-y: scroll;
  // display: block;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  opacity: 0;
  // top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 10s forwards;
  // animation-delay: 10s;
  margin-bottom: 25px;
}

.about-page,
.project-page,
.contact-page {
  .text-zone {
    display: flex;
    width: 50%;    
    flex-direction:column;
    align-items: center;
    // scroll-padding-bottom:0;
    // justify-content: center;
  }

  p {
    min-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.3s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.4s;
    }
  }

  ul {
    padding: 0;
  }

}
@media only screen and (max-width: 1024) {
  .container {
    flex-wrap: wrap;
  }
}
