/* CSS Variables */
:root {
  --rose: #ff0067;
  --rose65: #ff4d7f;
}


p {
  font-family: 'Outfit', sans-serif;
  line-height: 0.5;
  margin-bottom: 0px;
  letter-spacing: 1px;
}

.contact-text {
font-family: 'Outfit', sans-serif;
font-size: 19px;
font-weight: 200;
  line-height: 0.5;
  margin-left: 0;
}

.responsive-img {
  width: 225px;
  margin-bottom: 10px;
  margin-top: 0;
  border-radius: 5%;
}

h3 {
  font-family: 'Condiment', sans-serif;
  font-size: calc(4.3rem + 0.390625vw);
  line-height: 2.75rem;
  // bottom:0;
  color: #ff0067;
    white-space: nowrap;
}s

.contactForm {
  display: flex;
  flex-wrap: wrap;
}

input[type='text' i]:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 2px solid var(--rose);
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
  font-family:'Source Code Pro',monospace;
  font-weight:400;
  font-style:normal;
  font-size: 16pt;
}

input {
  border-bottom: 2px solid var(--rose);
  border-top: 1px solid #20252d;
  border-left: 1px solid #20252d;
  border-right: 1px solid #20252d;
  padding-bottom: 3px;
  padding-top: 10px;
  margin-bottom: 15px;
  position: relative;
  background-color: currentColor;
  text-decoration: none;
  outline: none;
  font-family:'Source Code Pro',monospace;
  font-weight:400;
  font-style:normal;
  font-size: 16pt;
  text-transform: uppercase;
  border-style: solid;
  box-shadow: none;
}

input::placeholder {
  color: var(--rose);
}

input[type='text'] {
  background-color: #20252d;
  color: white;
}

input[type='email'] {
  background-color: #20252d;
  color: white;
}

input:focus {
  border-bottom: 2px solid var(--rose);
  font-family:'Source Code Pro',monospace;
  font-weight:400;
  font-style:normal;
  font-size: 16pt;
  text-transform: uppercase;
}

input:focus::placeholder {
  color: #fff;
}

ul {
  margin:0;
  li {
    padding-right: 10%;
    list-style: none;
    margin-bottom: 4px;
    opacity: 0;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
    clear: none;
    &:first-child {
      margin-right: 0;
    }
  }
}

.error-container {
  width: 100%;
  border-radius: 0.3rem;
  padding: 0.2rem;
}

.error-text {
font-family: 'Source Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11pt;
  color: var(--rose);
  margin-bottom: 25px;
}

textarea {
  position: relative;
  height: 100px;
  border: none;
  padding: 15px 18px;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 16pt;
  line-height: 1.25;
  text-decoration: none;
  outline: none;
  border-radius: 0;
  color: #000;
}

.btnn-hover2 {
  display: block;
  font-family: 'Source Code Pro',monospace;
  font-style: normal;
  font-size: 16pt;
  font-weight: 600;
  line-height: 50px;
  padding: 0px 25px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 63px;
  height: 50px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  text-decoration: none;
  animation: fadeIn 1s 1.8s backwards;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  border-radius: 25px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btnn-hover2:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btnn-hover2:focus {
  outline: none;
}

.btnn-hover2.color-8 {
color: #171b21;
background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a , #FBB03B);
}

@media (max-width: 1280px) {
  section {
    column-count: 1;
  }

  /* Mobile Devices 280px and Under */
  @media (max-width: 360px) {
    .mobile {
      display: content;
    }
}
}
